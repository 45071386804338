import React, { useEffect } from 'react';
import './Information.scss';
import Rating from 'components/general/Rating';
import { calculatePriceDetails } from 'utils/product';
import CurrencyFormat from 'components/general/CurrencyFormat';
import AddToCard from 'components/product/AddToCart';
import { Link } from 'react-router-dom';
import { Container, Card, CardContent, CardMedia, CardHeader, Button, Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Helmet } from 'react-helmet';

function Information({ product, person, tag, source }) {
  console.log('product in information is ' + product?.id);
  debugger;
  console.log('person is ' + person);
  // debugger
  console.log('Source is ' + source);
  let text = '',
    link = '',
    prd = product?.links?.avinashbmv,
    prd1 = '',
    prd2 = '',
    prd3 = '',
    prd4 = '',
    prd5 = '',
    prd6 = '',
    prd7 = '',
    prd8 = '',
    prd9 = '',
    prd10 = '',
    wats,
    watsMsg,
    usmMsg,
    watsError,
    btntext = 'Buy in Amazon';
  let start = 0;
  let b = '',
    len;
  let pricearr;

  let j = 0;
  let price = 0,
    discount = 0,
    rating = 0;
  let a = '',
    k = 0,
    anum = 0;
  // let titlearr,newtitle;
  let i, title, titlearr, newtitle = ""
  newtitle = product?.productText || "";
  if (product?.productText == "") {
    newtitle = product?.urltext
  }


  // Title filter
  // if(newtitle==""){
  //   newtitle = product?.urltext
  // }
  // if (newtitle.includes(",")) {
  //   newtitle = newtitle.substring(0, newtitle.indexOf(","));
  // }
  // if (newtitle.includes("|")) {
  //   newtitle = newtitle.substring(0, newtitle.indexOf("|"));
  // }
  // if (newtitle.includes("(")) {
  //   newtitle = newtitle.substring(0, newtitle.indexOf("("));
  // }




  let tagid = "dealshubglo04-21"
  let startTimeStamp = 1706886912000;
  try {
    if (product != undefined && product?.price != undefined) {
      price = product?.price;
    }
    if (product != undefined && product?.discount != undefined) {
      discount = product?.discount;
    }
  } catch (e) {
    console.log('len Error');
  }
  try {
    watsMsg = `https://wa.me/919951797149?text=Interested%20in%20${product?.id}`;
    usmMsg = `https://wa.me/919951797149?text=Interested%20in%20USM-Go-Ranch`;
    watsError = `https://wa.me/919951797149?text=Error%20in%20${product?.id}`;
    // text = '';
    text = product?.urltext;
    link = watsError;
  } catch (e) {
    console.log(e);
  }
  try {

    try {
    } catch (e) {
      console.log(e);
    }

    if (newtitle.length < 10) {
      if (product?.category?.c5 != "") {
        newtitle = product?.category.c5
      }
      else if (product?.category?.c4 != "") {
        newtitle = product?.category.c4
      }
      else if (product?.category?.c3 != "") {
        newtitle = product?.category.c3
      }
      else if (product?.category?.c2 != "") {
        newtitle = product?.category.c2
      }
      else if (product?.category?.c1 != "") {
        newtitle = product?.category.c1
      }
    }

    if (tag == 'items') {
      link = watsMsg;
      btntext = 'Interested';
    }
    if (product?.id == '14261' && tag == 'items') {
      link = usmMsg;
    }
    // <div className="information__header">{product?.text_entities[0].type!="link" ? product?.text_entities[0].text : "hai"}</div>
  } catch (error) {
    console.log('Error in Info' + error);
  }

  try {
  } catch (e) {
    console.log(e);
  }

  if (discount > 80) {
    rating = 5;
  } else if (discount > 40) {
    rating = 4;
  } else if (discount > 20) {
    rating = 3;
  } else {
    rating = 2;
  }

  useEffect(() => {
    document.title = newtitle;
    return () => {
      document.title = newtitle;
    };
  }, []);

  if (product?.links?.avinashbmv != '') {
    prd1 = product?.links?.avinashbmv;
  } else if (product?.links?.avinashbmvINR != '') {
    prd1 = "https://" + product?.links?.avinashbmvINR;
  }

  if (tag == 'avinash') {
    // path=`/product/${product?.id}`
    link = prd1;
  } else if (tag == 'others') {
    // path=`/deals/product/${product?.id}`
    link = prd2;
    if (prd2 == '') {
      link = prd1;
    }
  } else if (tag == 'deals') {
    // path=`/deals/product/${product?.id}`
    link = prd3;
    if (prd3 == '') {
      link = prd1;
    }
  }
  // else if(tag=="offers"){
  //   // path=`/offers/product/${product?.id}`
  //   link=prd3
  //   if(prd3==""){
  //     link=prd1
  //   }
  // }
  else if (tag == 'avideals') {
    // path=`/deals/product/${product?.id}`
    link = prd4;
    if (prd4 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals5') {
    // path=`/deals/product/${product?.id}`
    link = prd5;
    if (prd5 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals6') {
    // path=`/deals/product/${product?.id}`
    link = prd6;
    if (prd6 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals7') {
    // path=`/deals/product/${product?.id}`
    link = prd7;
    if (prd7 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals8') {
    // path=`/deals/product/${product?.id}`
    link = prd8;
    if (prd8 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals9') {
    // path=`/deals/product/${product?.id}`
    link = prd9;
    if (prd9 == '') {
      link = prd1;
    }
  } else if (tag == 'avideals10') {
    // path=`/deals/product/${product?.id}`
    link = prd10;
    if (prd10 == '') {
      link = prd1;
    }
  } else if (tag == 'items') {
    // path=`/items/product/${product?.id}`
  }

  prd = prd1;

  if (product?.datetime > startTimeStamp) {
    console.log("Short Link")
    // prd = product?.links?.avinashbmv;
  }
  else if (product?.storeType != "Amazon") {
    console.log("Custom Link")
    prd = `https://amazon.in/dp/${product?.productCode}?tag=${tagid}`
  }

  const descriptionFields = Object.values(product?.description || {}).filter(desc => desc !== '' && !desc.includes('<'));

  return (
    <>
      <Helmet>
        <meta property="og:title" content={newtitle} />
        <meta property="og:description" content="Description " />
        <meta property="og:image" content={product?.photo} />
        <meta property="og:url" content={prd} />
        <meta property="og:type" content="website" />
      </Helmet>
      {product && (
        <Box className="information">
          <Typography variant="h4" className="information__header">
            {newtitle}
          </Typography>

          {source === 'main' && price > 0 && (
            <Box className="information__pricing">
              <Typography className='information__price' variant="h4">
                <span>Deal Price : </span>

                {/* <span className='information__label'>Deal Price : </span> */}
                <CurrencyFormat
                  className="product-card__amount"
                  currencyCode="INR"
                  value={product?.price}
                />
              </Typography>
            </Box>
          )}

          {/* {source === 'main' && rating > 0 && tag !== 'items' && ( */}
          {source === 'main' && discount > 0 && tag !== 'items' && (
            <Box className="product-card__rating">
              {/* <Typography variant="h4"> */}
              {/* <span>Rating : </span> */}
              {/* </Typography> */}
              {/* <Rating rating={rating} maxRating={5} size={2} /> */}
              <Typography className='information__price' variant="h4">Discount : </Typography>
              {/* <Typography className='information__price' variant="h4">Discount : {product?.discount}%</Typography> */}
              {/* <br /> */}
              {/* <span className='information__label'>Discount : {product?.discount}%</span> */}
              {/* <br /> */}
              <Typography className='information__price' variant="h4">{discount}%</Typography>
              {/* <span className='product-card__price'>{product?.discount}%</span> */}
              {/* <span className='product-card__amount'>{product?.discount}%</span> */}
            </Box>
          )}

          {prd != '' ? (
            <Link to={{ pathname: prd }} target="_blank">
              {product && product?.storeType && product?.storeType != "" ? (
                <Button
                  variant="contained"
                  color="primary"
                  // className="add-to-cart-button" 
                  style={{
                    //   // color: 'crimson',
                    fontSize: 15,
                    lineHeight: 1,
                    alignContent: 'center',
                    fillOpacity: 'blue',
                  }}
                >
                  Buy in {product?.storeType}
                </Button>

              ) : product &&
                product?.links &&
                product?.links?.avinashbmv &&
                product?.links?.avinashbmv.includes('amzn') ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    // color: 'crimson',
                    fontSize: 15,
                    lineHeight: 1,
                    alignContent: 'center',
                    fillOpacity: 'blue',
                  }}
                >
                  Buy
                </Button>
                // <button className="add-to-cart-button" style={{
                //   color: 'crimson',
                //   fontSize: 20,
                //   lineHeight: 1,
                //   alignContent: 'center',
                //   fillOpacity: 'blue',
                // }}>Buy in Amazon</button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                // style={{
                //   color: 'crimson',
                //   fontSize: 20,
                //   lineHeight: 1,
                //   alignContent: 'center',
                //   fillOpacity: 'blue',
                // }}
                >
                  Buy
                </Button>
                // <button className="add-to-cart-button" style={{
                //   color: 'crimson',
                //   fontSize: 20,
                //   lineHeight: 1,
                //   alignContent: 'center',
                //   fillOpacity: 'blue',
                // }}>Buy</button>
              )}

              {/* <button
                className="add-to-cart-button"
                style={{
                  color: 'crimson',
                  fontSize: 20,
                  lineHeight: 1,
                  alignContent: 'center',
                  fillOpacity: 'blue',
                }}
              >
                {btntext}
              </button> */}
              {/* <button className='add-to-cart-button' style={{ color: 'crimson', fontSize:20, lineHeight : 1 ,marginLeft: 100, fillOpacity:'blue'}}>{btntext}</button> */}
            </Link>
          ) : null}






          {/*           

        {link !== '' && (
          <Link to={{ pathname: link }} target="_blank">
            <Button variant="contained" color="primary" className="add-to-cart-button">
              {product && product?.storeType && product?.storeType!=""
                ? `Buy in ${product?.storeType}`
                : product &&
                  product?.links &&
                  product?.links?.avinashbmv &&
                  product?.links?.avinashbmv.includes('amzn')
                ? 'Buy in Amazon'
                : 'Buy'}
            </Button>

            <Button
              variant="contained"
              color="secondary"
              style={{
                color: 'crimson',
                fontSize: 20,
                lineHeight: 1,
                alignContent: 'center',
                fillOpacity: 'blue',
              }}
            >
              {btntext}
            </Button>
          </Link>
        )} */}
          {/* <br/> */}
          <br />

          {descriptionFields.length > 0 && (
            <Box className="information__description">
              <Typography variant="h5" style={{ fontSize: '2.2rem' }}>Description:</Typography>
              <List>
                {descriptionFields.map((desc, index) => (
                  <ListItem key={index}>
                    <Typography variant="body1" style={{ fontSize: '2rem' }}>
                      {desc}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <Link to="/home" className="logo">
            <Button
              variant="contained"
              color="primary"
              style={{
                //   color: 'crimson',
                fontSize: 15,
                lineHeight: 1,
                alignContent: 'right',
                fillOpacity: 'blue',
              }}
            >
              View all Products
            </Button>
          </Link>

          <Link to={{ pathname: link }} target="_blank">
            <Typography variant="body1">Facing Issue ?</Typography>
          </Link>
        </Box>
      )}
    </>
  );
}

export default Information;