import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';
import {
  Container,
  Grid,
  Button,
  Collapse,
  Switch,
  FormControlLabel,
  Box,
  Typography
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useInView } from 'react-intersection-observer';
import { format } from 'date-fns';

import productsApi from 'api/products';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import PlatformFilters from 'Filters/PlatformFilters';
import MediaCard from './CardProduct';

const PAGE_SIZE = 30;

const Products = ({ tag, category, source, searchValue }) => {
  const { asinId } = useParams();
  const { pathname } = useLocation();

  // State for products and caching (to avoid repeated API calls)
  const [products, setProducts] = useState([]);
  const productsCache = useRef(null);

  // Filters state (managed locally)
  const [filters, setFilters] = useState({
    askPrice: 0,
    askDiscount: 0,
    searchValue: '',
    stores: ["Amazon", "Flipkart", "Myntra", "Ajio"],
    dealsFiltertogg: false,
    showFilters: false,
    selectedCategory: '' // empty means "show all"
  });

  // Loading and error states
  const [loading, setLoading] = useState(false);
  const [processingFilters, setProcessingFilters] = useState(false);
  const [error, setError] = useState(null);

  // Grouped view flag – when true, products are grouped by category.
  const [groupByCategory, setGroupByCategory] = useState(true);
  const [visibleCount, setVisibleCount] = useState(PAGE_SIZE);

  // Update filters using a functional update.
  const updateFilters = useCallback((newFilters) => {
    setFilters(prev => ({ ...prev, ...newFilters }));
  }, []);

  // When a category button is clicked, toggle the selectedCategory.
  const handleCategoryClick = (cat) => {
    setProcessingFilters(true);
    setFilters(prev => ({
      ...prev,
      // Toggle: if the clicked category is already selected, clear it; otherwise, set it.
      selectedCategory: prev.selectedCategory === cat ? '' : cat
    }));
    window.scrollTo(0, 0);
    setTimeout(() => setProcessingFilters(false), 300);
  };

  // Toggle between grouped view and flat view.
  const toggleGroupBy = () => {
    setProcessingFilters(true);
    setGroupByCategory(prev => {
      if (prev) {
        updateFilters({ selectedCategory: '' });
      }
      return !prev;
    });
    window.scrollTo(0, 0);
    setTimeout(() => setProcessingFilters(false), 300);
  };

  // Handle applying filters from PlatformFilters (price, discount, stores).
  const handleApplyFilters = (newFilterValues) => {
    setProcessingFilters(true);
    updateFilters({
      askPrice: Number(newFilterValues.askPrice) || 0,
      askDiscount: Number(newFilterValues.askDiscount) || 0,
      stores: newFilterValues.stores
    });
    window.scrollTo(0, 0);
    setTimeout(() => setProcessingFilters(false), 300);
  };

  // Load products from API (with caching).
  const loadProducts = useCallback(async () => {
    if (productsCache.current) {
      setProducts(productsCache.current);
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const currentDate = new Date();
      const dateStr = format(currentDate, 'yyyy-MM-dd');
      let json = await productsApi.getList(dateStr, filters.dealsFiltertogg);
      if (!json || Object.keys(json).length === 0) {
        const previousDate = format(
          new Date(currentDate.setDate(currentDate.getDate() - 1)),
          'yyyy-MM-dd'
        );
        json = await productsApi.getList(previousDate, filters.dealsFiltertogg);
      }
      const productArray = Array.isArray(json) ? json : Object.values(json);
      productsCache.current = productArray;
      setProducts(productArray);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }, [filters.dealsFiltertogg]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  useEffect(() => {
    setVisibleCount(PAGE_SIZE);
  }, [filters, pathname]);

  // Memoized Fuse instance for search functionality.
  const fuse = useMemo(() => {
    return new Fuse(products, {
      keys: [
        { name: 'urltext', weight: 1.0 },
        { name: 'productText', weight: 0.8 },
        { name: 'shortText', weight: 0.7 },
        { name: 'description.d1', weight: 0.6 }
      ],
      threshold: 0.3,
      shouldSort: true,
      ignoreLocation: true
    });
  }, [products]);

  // Process products by applying search and filters.
  const processedProducts = useMemo(() => {
    let prodArray = products;
    if (filters.searchValue.trim() && products.length > 0) {
      const fuseResults = fuse.search(filters.searchValue);
      prodArray = fuseResults.map(result => result.item);
    }
    const filtered = prodArray.filter(product => {
      const productPrice = Number(product.price);
      const productDiscount = Number(product.discount);
      const minPrice = Number(filters.askPrice) || 0;
      const minDiscount = Number(filters.askDiscount) || 0;
      const meetsPrice = productPrice >= minPrice;
      const meetsDiscount = productDiscount >= minDiscount;
      // Compare store names in lowercase.
      const productStore = (product.storeType || "").toLowerCase();
      const selectedStores = filters.stores.map(s => s.toLowerCase());
      const meetsStore = selectedStores.includes(productStore);
      // Use an "includes" check for category filtering.
      const meetsCategory = filters.selectedCategory
        ? ((product.category?.c1 || "").toLowerCase().includes(filters.selectedCategory.toLowerCase()))
        : true;
      return product.isDisplay && meetsPrice && meetsDiscount && meetsStore && meetsCategory;
    });
    if (groupByCategory) {
      const grouped = {};
      filtered.forEach(product => {
        const cat = product.category?.c1 || "Uncategorized";
        if (!grouped[cat]) grouped[cat] = [];
        grouped[cat].push(product);
      });
      return grouped;
    } else {
      return filtered;
    }
  }, [
    products,
    filters.searchValue,
    filters.askPrice,
    filters.askDiscount,
    filters.stores,
    filters.selectedCategory,
    fuse,
    groupByCategory
  ]);

  // For lazy loading in flat view.
  const visibleProducts = useMemo(() => {
    if (!groupByCategory) {
      return processedProducts.slice(0, visibleCount);
    }
    return processedProducts;
  }, [processedProducts, visibleCount, groupByCategory]);

  // Intersection observer to load more products (flat view).
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0,
    rootMargin: '200px'
  });
  useEffect(() => {
    if (inView && !groupByCategory && visibleCount < processedProducts.length) {
      setVisibleCount(prev => Math.min(prev + PAGE_SIZE, processedProducts.length));
    }
  }, [inView, processedProducts.length, groupByCategory]);

  // Compute available categories.
  const allCategories = useMemo(() => {
    if (groupByCategory) {
      // Use the keys from the grouped data.
      return Object.keys(processedProducts);
    } else {
      const cats = new Set();
      products.forEach(product => {
        cats.add(product.category?.c1 || "Uncategorized");
      });
      return Array.from(cats);
    }
  }, [groupByCategory, processedProducts, products]);

  // Debug logs.
  useEffect(() => {
    console.log("Selected category changed to:", filters.selectedCategory);
  }, [filters.selectedCategory]);

  useEffect(() => {
    console.log("Processed products:", processedProducts);
  }, [processedProducts]);

  // Header: horizontal category bar on top; below that, the group toggle and Filters button.
  const Header = () => (
    <>
      {/* <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap', mb: 1 }}>
        {allCategories.map((cat) => (
          <Button
            key={cat}
            variant={filters.selectedCategory === cat ? "contained" : "outlined"}
            onClick={() => handleCategoryClick(cat)}
            sx={{ mr: 1, mb: 1 }}
          >
            {cat}
          </Button>
        ))}
      </Box> */}
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <FormControlLabel
          control={<Switch checked={groupByCategory} onChange={toggleGroupBy} color="primary" />}
          label="Group by Category"
        />
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={() => {
            updateFilters({ showFilters: !filters.showFilters });
            window.scrollTo(0, 0);
          }}
          sx={{ ml: 2 }}
        >
          Filters
        </Button>
      </Box>
    </>
  );

  // Render products: grouped view or flat view.
  const renderProducts = () => {
    if (groupByCategory) {
      if (Object.keys(processedProducts).length === 0) {
        return <Typography>No products found for the selected category.</Typography>;
      }
      return Object.entries(processedProducts).map(([cat, items]) => (
        <Box key={cat} mb={4}>
          <Typography variant="h5" gutterBottom>{cat}</Typography>
          <Grid container spacing={2}>
            {items.map(product => (
              <Grid item xs={6} sm={4} md={3} lg={3} xl={2} key={product.id}>
                <MediaCard product={product} tag={tag} source={source} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ));
    } else {
      if (visibleProducts.length === 0) {
        return <Typography>No products found for the selected category.</Typography>;
      }
      return (
        <Grid container spacing={2}>
          {visibleProducts.map(product => (
            <Grid item xs={6} sm={4} md={3} lg={3} xl={2} key={product.id}>
              <MediaCard product={product} tag={tag} source={source} />
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  if (loading) return <Loader size={50} />;
  if (error) return <Error message="Failed to load products" actionFn={loadProducts} />;

  return (
    <Container maxWidth="xl">
      <Header />
      {processingFilters && <Loader size={50} />}
      <Collapse in={filters.showFilters}>
        <PlatformFilters filters={filters} onApply={handleApplyFilters} />
      </Collapse>
      {renderProducts()}
      {!groupByCategory && <div ref={loadMoreRef} style={{ height: '20px' }} />}
      <Box mt={2}>
        <Button variant="contained" onClick={() => updateFilters({ dealsFiltertogg: !filters.dealsFiltertogg })}>
          Toggle Deals
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant="caption">
          Disclaimer: As an Amazon Associate, I earn commission from qualifying purchases.
        </Typography>
      </Box>
    </Container>
  );
};

export default React.memo(Products);
