import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, TextField, Button, Typography } from "@mui/material";
import styles from "./PlatformFilters.module.scss";
import { usePlatforms } from "../hooks/usePlatforms";
// import { Checkbox, FormControlLabel } from "@mui/material";

const stores = ["Amazon", "Flipkart", "Myntra", "Ajio"];

const PlatformFilters = ({ filters, onApply }) => {
  // Local state holds temporary filter values.
  const [localFilters, setLocalFilters] = useState({
    askPrice: filters.askPrice,
    askDiscount: filters.askDiscount,
    stores: filters.stores,
  });

  // Sync local state when parent filters change.
  useEffect(() => {
    setLocalFilters({
      askPrice: filters.askPrice,
      askDiscount: filters.askDiscount,
      stores: filters.stores,
    });
  }, [filters.askPrice, filters.askDiscount, filters.stores]);

  const handlePriceChange = (e) => {
    setLocalFilters(prev => ({ ...prev, askPrice: e.target.value }));
  };

  const handleDiscountChange = (e) => {
    setLocalFilters(prev => ({ ...prev, askDiscount: e.target.value }));
  };

  const handleStoreChange = (store) => {
    setLocalFilters(prev => {
      let updatedStores = [...prev.stores];
      if (updatedStores.includes(store)) {
        // Remove store if unchecked.
        updatedStores = updatedStores.filter(s => s !== store);
      } else {
        updatedStores.push(store);
      }
      return { ...prev, stores: updatedStores };
    });
  };

  const handleAllChange = (e) => {
    if (e.target.checked) {
      setLocalFilters(prev => ({ ...prev, stores: stores }));
    } else {
      setLocalFilters(prev => ({ ...prev, stores: [] }));
    }
  };

  // Check if all stores are selected
  const isAllSelected = localFilters.stores.length === stores.length;

  return (
    <Box className={styles.filters} p={2} border="1px solid #ccc" borderRadius="4px" mb={2}>
      <Typography variant="h6" gutterBottom>Filters</Typography>
      <Box mb={2}>
        <Typography variant="subtitle1">Stores</Typography>
        <Box display="flex" flexWrap="wrap">
          <FormControlLabel
            label="All"
            control={
              <Checkbox
                checked={isAllSelected}
                onChange={handleAllChange}
                color="primary"
              />
            }
          />
          {stores.map(store => (
            <FormControlLabel
              key={store}
              label={store}
              control={
                <Checkbox
                  checked={localFilters.stores.includes(store)}
                  onChange={() => handleStoreChange(store)}
                  color="primary"
                />
              }
            />
          ))}
        </Box>
      </Box>
      <Box mb={2} display="flex" gap={2}>
        <TextField
          label="Minimum Price"
          type="number"
          value={localFilters.askPrice}
          onChange={handlePriceChange}
          variant="outlined"
          size="small"
        />
        <TextField
          label="Minimum Discount"
          type="number"
          value={localFilters.askDiscount}
          onChange={handleDiscountChange}
          variant="outlined"
          size="small"
        />
      </Box>
      <Button variant="contained" size="small" color="primary" onClick={() => onApply(localFilters)}>
        Apply
      </Button>
    </Box>
  );
};

export default PlatformFilters;
