import React, { useCallback, useEffect, useState } from 'react';

import './Products.scss';

import productsApi from 'api/products';
import Loader from 'components/general/Loader';
import Error from 'components/general/Error';
import Information from '../../components/product/Information';
import ProductCard from './ProductCard';
// import CardProduct from './CardProduct';
import Input from './Input';
import Deals from './Deals';
// import Container from './@mui/material/core/Container';
import { useLocation, useParams } from 'react-router-dom';

// import Container from '@mui/material/core/Container';
// import Card from '@mui/material/core/Card';
// import CardContent from '@mui/material/core/CardContent';
// import CardMedia from '@mui/material/core/CardMedia';
// import CardHeader from '@mui/material/core/CardHeader';
// import Grid from '@mui/material/Grid';
import { Container, Card, CardContent, CardMedia, CardHeader, Grid, Button, Menu, MenuItem, Typography } from '@mui/material';
import useStyles from './productcd';
import Items from './Items';
// import { orange } from '@mui/material';
import { format } from 'date-fns';
import CardProd from './CardProd';
import MediaCard from './CardProduct';
import RecipeReviewCard from './avatar';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

// require("core-js/actual/array/group-by-to-map");
// require("core-js/actual/array/group-by");

// import FlagPage from '../flag/index';
let categoryvalue = '';
let arr = ''




function getUniqueProductsByAsin(apiData) {
  // Use reduce to build the uniqueProducts object
  const uniqueProducts = Object.values(apiData).reduce((result, product) => {
    const asin = product?.productCode;

    if (!result[asin] || !asin) {
      result[asin] = product;
    } else {
      console.log("Duplicate id", product?.id)
    }

    return result;
  }, {});
  console.log("uniqueProducts is ", uniqueProducts)
  const resultArray = Object.values(uniqueProducts);
  console.log("resultArray is ", resultArray)
  return uniqueProducts;
  // return resultArray;
}



// Function to group products based on category 'c1'
function groupProductsByCategory(products) {
  return Object.values(products).reduce((result, product) => {
    const categoryKey = product.category.c1.trim(); // Assuming you want to remove leading/trailing whitespaces

    if (!result[categoryKey]) {
      result[categoryKey] = [];
    }

    result[categoryKey].push(product);
    return result;
  }, {});
}

// function groupProductsByCategory(products) {
//   debugger
//   return products.reduce((result, product) => {
//     const categoryKey = product.category.c1;

//     if (!result[categoryKey]) {
//       result[categoryKey] = [];
//     }

//     result[categoryKey].push(product);
//     return result;
//   }, {});
// }
function Productsloots(data) {
  debugger
  let { asinId } = useParams();
  console.log('Tag value is ' + data.tag);
  console.log('Category value is ' + data.category);
  let tag = data.tag;
  // let source = data.source;
  console.log('Asin Value is ' + asinId);
  console.log('Source Value is ' + data.source);
  // if(asinId != ""){

  const classes = useStyles();

  // }
  // function Products(setCounted) {
  const [products, setProducts] = useState([]);
  const [categorizedProducts, setCategorizedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [askDiscount, setAskDiscount] = useState(data.discount);
  const [askPrice, setAskPrice] = useState(data.price);
  const [source, setSource] = useState(data.source);
  const [error, setError] = useState(null);
  const [filtertogg, setfiltertogg] = useState(false);
  const [dealsFiltertogg, setdealsFiltertogg] = useState(false);
  const [asinFlag, setAsinFlag] = useState(false);
  // const [asinFlag, setAsinFlag] = useState(data.asin!=undefined?asin:false);

  const [price, setprice] = useState(0);
  const [discount, setdiscount] = useState(0);

  // const [cat, setCat] = useState("");
  // const [category, setCategory] = useState(asinId != undefined ? asinId.length==10? asinId :"" : "");
  const [category, setCategory] = useState(
    asinId != undefined ? asinId : data.category,
  );
  //  data.tag=="avinash"?data.category:"");
  const [searchValue, setSearchValue] = useState(
    data.searchValue != undefined ? data.searchValue : '',
  );
  // const [searchValue, setSearchValue] = useState(data.searchValue!=undefined?data.searchValue:"");
  // data.tag=="avinash"?data.searchValue:"");
  // const [category, setCategory] = useState(data.category);
  const [isAmazonSelected, setIsAmazonSelected] = useState(true);
  const [isFlipkartSelected, setIsFlipkartSelected] = useState(true);
  const [isAllSelected, setIsAllSelected] = useState(true);




  const [anchorElC1, setAnchorElC1] = useState(null);
  const [anchorElC2, setAnchorElC2] = useState(null);
  const [hoveredC1, setHoveredC1] = useState(null);
  const [hoveredC2, setHoveredC2] = useState(null);


  const { pathname } = useLocation();
  let cat = '';
  let uniqueProductsOutput;
  // date;
  let len = 0;
  const currentDate = new Date(); // Get the current date

  // Format the date in "yyyy-MM-dd" format
  let date = format(currentDate, 'yyyy-MM-dd');

  currentDate.setDate(currentDate.getDate() - 1);

  // Format the previous day's date in "yyyy-MM-dd" format
  const previousDate = format(currentDate, 'yyyy-MM-dd');
  // date = new Date();
  console.log("Today's Date is " + date);
  // date=Input[0].date.split('T');
  console.log('Date is ', date);
  try {
    // len=products.text_entities.length-1;
    // if(Input[0].date.split('T')!=undefined){

    console.log('Search Value is ' + searchValue);
    console.log('Search Value default is ' + data.searchValue);
    // }
  } catch (e) {
    console.log('Date Error');
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, searchValue, category]);

  useEffect(() => {
    // preventDefault();
    if (data != undefined) {
      if (tag != 'items') {
        if (asinId != undefined && asinFlag == false) {
          setCategory(asinId);
          // asinCount+=1
          // if(asinCount==2)
        } else {
          setCategory(data.category);
        }
        if (data.searchValue != undefined) setSearchValue(data.searchValue);
        setdealsFiltertogg(false);
      }
      if (tag != 'items' && category == 'All Categories') {
        setCategory('');
      }
      if (
        tag != 'items' &&
        data.category != 'All Categories' &&
        data.category != ''
      ) {
        setAsinFlag(true);
        setCategory(data.category);
      }
    }
  }, [data]);
  useEffect(() => {
    if (asinId != undefined) {
      // setCategory(data.category);
      setCategory(asinId);
    } else {
      // setCategory(data.category);
    }
  }, [asinId]);
  useEffect(() => {
    if (data.searchValue != undefined)
      // if(data.searchValue!=undefined && searchValue!=data.searchValue) //Search Value Check change
      setSearchValue(data.searchValue);
  }, [searchValue, data.searchValue]);

  // useEffect(() => {
  //   if (product && product.tipe && product.tipe!=undefined){
  //     product.tipe=product.tipe
  //   } else {
  //     if(products.links.avinashbmv.includes("amzn")){
  //       product.tipe="Amazon";
  //     } else{
  //       products.tipe="Flipkart"
  //     }
  //   }
  // }, [searchValue, data.searchValue]);

  useEffect(() => {
    if (isAmazonSelected && isFlipkartSelected) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [isAmazonSelected, isFlipkartSelected]);

  // let { check } = useParams();
  // console.log('Params is '+check);
  const searchHandler = (event) => {
    setCategory(event.target.value);
    // setCategory(category.toLowerCase());
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name == 'amazon') {
      setIsAmazonSelected(checked);
    } else if (name == 'flipkart') {
      setIsFlipkartSelected(checked);
    } else if (name == 'all') {
      setIsAllSelected(checked);
    }
  };

  // if(asinId!=undefined){
  //   setCategory(asinId);
  // }

  let i;


  // const groupByCategoryReduce = Object.values(products).reduce(
  //   (group, product) => {
  //     // const groupByCategoryReduce = products.reduce((products[key].category, product) => {

  //     // let { cat } = product.category.c1;
  //     let { cat } = product;
  //     // setCat(product);
  //     try {
  //       //     if(product.text_entities!=undefined)
  //       // for(i=0;i<product?.text_entities?.length;i++){
  //       //   if(product.text_entities[i].type=="hashtag"){
  //       //     cat=product.text_entities[i].text.substr(1);
  //       //     // setCat(product.text_entities[i].text.substr(1));
  //       //   }

  //       // }
  //       if (cat == '') {
  //         cat = 'all';
  //         // setCat("all");
  //       }
  //     } catch (e) {
  //       console.log('Undefined Len Err');
  //     }

  //     // const { category } = product;
  //     // console.log('product in reduce is '+{product});
  //     // console.log('group in reduce is '+{group});

  //     // group[category] = group[category] ?? [];
  //     // group[category].push(product);
  //     group[cat] = group[cat] ?? [];
  //     group[cat].push(product);
  //     return group;
  //   },
  //   {},
  // );
  // // debugger
  // console.log('groupByCategoryReduce is ' + products);
  // console.log('groupByCategoryReduce is ' + groupByCategoryReduce);
  // // console.log('Json groupByCategoryReduce is '+ JSON.parse(groupByCategoryReduce));
  // console.log(
  //   'Json groupByCategoryReduce is ' +
  //     JSON.stringify(groupByCategoryReduce.Beauty),
  // );
  // const arr = { ...groupByCategoryReduce };
  // // setCategorizedProducts(JSON.stringify(groupByCategoryReduce));
  // // setProducts(groupByCategoryReduce);
  // console.log('arr is ' + arr);

  // const groupByCategory = products.groupBy(product => {
  //   for(i=0;i<product.text_entities.length;i++){
  //     if(product.text_entities[i].type=="hashtag"){
  //       product.category=product.text_entities[i].text;
  //     }
  //   }
  //   if(product.category=""){
  //     product.category="others"
  //   }
  //   return product.category;
  // });
  // console.log(groupByCategory);

  // let date=Input[0].date.split('T');
  // console.log(date[0]);
  // const json = await productsApi.getList();
  const loadProducts = useCallback(async () => {
    if (
      loading ||
      Object.keys(categorizedProducts).length > 0 ||
      Object.keys(products).length > 1
    )
      return;
    // if (loading || products.length > 0) return;

    setLoading(true);
    setError(null);

    let json;
    try {
      if (tag == "loots") {
        debugger
        json = await productsApi.getList(date, dealsFiltertogg, "loots");

      }
      else if (tag == "bestselling") {
        debugger
        json = await productsApi.getList(date, dealsFiltertogg, "bestselling", "bs_Electronics");

      }
      else {
        debugger
        json = await productsApi.getList(date, dealsFiltertogg);

      }
      //  json = await productsApi.getList();
      console.log(json);

      if (Object.keys(json).length > 0) {
        // setdealsFiltertogg(true);
        // JSON object is not empty
        // You can proceed with further actions here
      } else {
        // setdealsFiltertogg(true);
        // if(tag=="loots"){
        //   debugger
        //   json = await productsApi.getList(previousDate,dealsFiltertogg,"loots");

        // }
        // else{
        debugger
        json = await productsApi.getList(previousDate, dealsFiltertogg);

        // }
        //  json = await productsApi.getList(previousDate,dealsFiltertogg);
        // JSON object is empty
        // Handle the case when there are no items in the JSON object
      }

      if (tag == 'items') {
        json = Items;
      } else {
        // json = Input;
        // json = Deals;
      }
      setProducts(json);
      // setProducts(...products,{})
      uniqueProductsOutput = getUniqueProductsByAsin(json)
      setCategorizedProducts(uniqueProductsOutput);
      const groupedProducts = groupProductsByCategory(json);
      // setCategorizedProducts(groupedProducts["Fashion"]);
      // const groupedProducts = groupProductsByCategory(products);
      debugger
      console.log(groupedProducts);
      console.log('Json value in Products is ' + json);
      // console.log(products.id);

    } catch (_error) {
      setError(_error);
    }
    setLoading(false);
  }, [loading, categorizedProducts, products]);


  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  if (categoryvalue == '') {
    categoryvalue = 'Realestate';
  }

  if (loading) {
    return <Loader size={50} />;
  } else if (error) {
    return <Error message="Failed to load products" actionFn={loadProducts} />;
  } else {
    let i;
    try {
      if (products.text_entities != undefined)
        for (i = 0; i < products.text_entities.length; i++) {
          if (products.text_entities[i].type == 'hashtag') {
            products.category = products.text_entities[i].text.substr(1);
          }
        }
      if ((products.category = '')) {
        products.category = 'others';
      }
    } catch (e) {
      console.log('len error');
    }

    const priceHandler = (event) => {
      setprice(event.target.value);
    };
    const submitHandler = () => {
      setAskDiscount(discount);
      setAskPrice(price);
      // setprice(event.target.value);
      // return(
      // <Products tag = {data.tag} category={data.category} source={data.source} discount={discount} price={price}/>
      // )
    };
    const discountHandler = (event) => {
      setdiscount(event.target.value);
    };
    const toggleHandler = (event) => {
      setfiltertogg(!filtertogg);
      // if (filtertogg) setfiltertogg(false);
      // else setfiltertogg(true);
    };
    const dealsToggleHandler = () => {
      setdealsFiltertogg(!dealsFiltertogg);
      // if (!dealsFiltertogg) setdealsFiltertogg(true);
      // else setdealsFiltertogg(false);
    };




    const handleC1Click = (event) => {
      setAnchorElC1(event.currentTarget);
    };

    const handleC2Click = (event) => {
      setAnchorElC2(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorElC1(null);
      setAnchorElC2(null);
    };

    const getC1Values = () => {
      // return [...new Set(products.map(product => product.category.c1))];
      setProducts(...new Set(products.map(product => product.category.c1)));
    };

    const getC2Values = (c1) => {
      setProducts(...new Set(products.filter(product => product.category.c1 === c1).map(product => product.category.c2)));
      // return [...new Set(products.filter(product => product.category.c1 === c1).map(product => product.category.c2))];
    };

    const getC3Values = (c1, c2) => {
      // return products
      setProducts(products
        .filter(product => product?.category?.c1 === c1 && product?.category?.c2 === c2)
        .map(product => product?.category?.c3));
    };






    //   const groupedProducts = products.reduce((result, product) => {
    //   const categoryKey = product.category.c1;

    //   if (!result[categoryKey]) {
    //     result[categoryKey] = [];
    //   }

    //   result[categoryKey].push(product);
    //   return result;
    // }, {});

    // console.log(groupedProducts);






    // const groupByCategoryReduce = products.reduce((group, product) => {
    //   let { cat } = product;

    //   for (let i = 0; i < Object.keys(products).length; i++) {
    //     if (product[i].category.c1 != "") {
    //       cat = product[i].category.c1;
    //     }
    //   }

    //   if (cat === "") {
    //     cat = "all";
    //   }

    //   group[cat] = group[cat] ?? [];
    //   group[cat].push(product);
    //   return group;
    // }, {});

    // // console.log('groupByCategoryReduce is ', groupByCategoryReduce);

    // arr = Object.values(groupByCategoryReduce);
    // const arr = Object.values(groupByCategoryReduce);
    // console.log('arr is ', arr);




    //   // const { category } = product;
    //   // console.log('product in reduce is '+{product});
    //   // console.log('group in reduce is '+{group});

    //   // group[category] = group[category] ?? [];
    //   // group[category].push(product);
    //   group[cat] = group[cat] ?? [];
    //   group[cat].push(product);
    //   return group;
    // }, {});

    //All Consoles
    // console.log('groupByCategoryReduce is '+ products);
    // console.log('groupByCategoryReduce is '+ groupByCategoryReduce);
    // const { arr } = {...groupByCategoryReduce};
    // // setProducts(groupByCategoryReduce);
    // console.log('arr is '+arr)

    return (
      <div id="slider" className="overflow-x-scroll scroll">

        {/* 

        <div>
      {getC1Values().map(c1 => (
        <Button key={c1} onMouseEnter={() => setHoveredC1(c1)} onMouseLeave={() => setHoveredC1(null)} onClick={handleC1Click}>
          {c1}
        </Button>
      ))}
      <Menu anchorEl={anchorElC1} open={Boolean(anchorElC1)} onClose={handleClose}>
        {getC1Values().map(c1 => (
          <MenuItem key={c1} onMouseEnter={() => setHoveredC1(c1)} onMouseLeave={() => setHoveredC1(null)} onClick={handleClose}>
            {c1}
          </MenuItem>
        ))}
      </Menu>

      {hoveredC1 && (
        <div>
          {getC2Values(hoveredC1).map(c2 => (
            <Button key={c2} onMouseEnter={() => setHoveredC2(c2)} onMouseLeave={() => setHoveredC2(null)} onClick={handleC2Click}>
              {c2}
            </Button>
          ))}
          <Menu anchorEl={anchorElC2} open={Boolean(anchorElC2)} onClose={handleClose}>
            {getC2Values(hoveredC1).map(c2 => (
              <MenuItem key={c2} onMouseEnter={() => setHoveredC2(c2)} onMouseLeave={() => setHoveredC2(null)} onClick={handleClose}>
                {c2}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}

      {hoveredC2 && (
        <div>
          {getC3Values(hoveredC1, hoveredC2).map(c3 => (
            <Typography key={c3}>{c3}</Typography>
          ))}
        </div>
      )}
    </div> */}
        <div>
          {/* <div> */}
          <div style={{ top: '10px', bottom: '10px', right: '10px', zIndex: 1000 }} >
            {/* <div style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }} > */}


            {/* <FilterAltOutlinedIcon size="250" onClick={toggleHandler} style={{ cursor: 'pointer' , fontSize: '32px', color: '#000' }} /> */}

            <Button
              variant="contained"
              color="primary"
              onClick={toggleHandler}
              endIcon={<FilterAltOutlinedIcon />}
              style={{
                // color: 'crimson',
                position: 'right',
                fontSize: 12,
                lineHeight: 1,
                alignContent: 'center',
                fillOpacity: 'blue',
                alignItems: 'right'
              }}
            >
              {filtertogg ? 'Hide Filters' : 'Show Filters'}
            </Button>
            {/* {data.tag!="items"?
        <button className='add-to-cart-button' onClick={toggleHandler}>Click to Sort</button>
        :null} */}
            {/* {data.tag!="items" && filtertogg? */}
            {filtertogg && tag != 'items' ? (
              // <div style={{ position: 'absolute', top: '60px', right: '0', padding: '10px', border: '1px solid #ccc', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', zIndex: 999 }}>
              <div>
                <div>
                  <label
                    style={{
                      // color: 'crimson',
                      fontSize: 15,
                      lineHeight: 1,
                      lineWidth: 8,
                      padding: 8,
                    }}
                  >
                    Price Range:&nbsp;
                    <input
                      type="text"
                      value={price}
                      style={{
                        // color: 'crimson',
                        lineHeight: 1,
                        lineWidth: 8,
                        leftpadding: 20,
                      }}
                      onChange={(event) => priceHandler(event)}
                    />
                  </label>
                </div>
                <br />
                <div>
                  <label
                    style={{
                      // color: 'crimson',
                      fontSize: 15,
                      lineHeight: 1,
                      lineWidth: 8,
                      padding: 8,
                    }}
                  >
                    Discount Range:&nbsp;
                    <input
                      type="text"
                      value={discount}
                      style={{
                        // color: 'crimson',
                        lineHeight: 1,
                        lineWidth: 8,
                        leftpadding: 20,
                      }}
                      onChange={(event) => discountHandler(event)}
                    />
                  </label>
                  <br />
                </div>
                <div></div>
                <br />
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="all"
                      checked={isAllSelected}
                      onChange={handleCheckboxChange}
                    />
                    All
                  </label>
                </div>
                <div></div>
                <br />
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="amazon"
                      checked={isAmazonSelected}
                      onChange={handleCheckboxChange}
                    />
                    Amazon
                  </label>
                  {/* </div> */}
                </div>
                <div></div>
                <br />
                <label>
                  <input
                    type="checkbox"
                    name="flipkart"
                    checked={isFlipkartSelected}
                    onChange={handleCheckboxChange}
                  />
                  Flipkart
                </label>
                <br />
                <br />

                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitHandler}
                    // className="add-to-cart-button" 
                    style={{
                      //   // color: 'crimson',
                      fontSize: 12,
                      lineHeight: 1,
                      alignContent: 'center',
                      fillOpacity: 'blue',
                    }}
                  >
                    Sort
                  </Button>
                  {/* <button onClick={submitHandler}>Sort</button> */}
                  {/* <button
                  className="add-to-cart-button"
                  onClick={submitHandler}
                  style={{
                    color: 'crimson',
                    fontSize: 15,
                    lineHeight: 1,
                    marginLeft: 70,
                    fillOpacity: 'blue',
                  }}
                >
                  Sort
                </button> */}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <br />
        <br />
        {/* <button onClick={submitHandler}>Submit</button> */}

        {/* <div class="sharethis-sticky-share-buttons"></div> */}
        <Container
          maxWidth="lg"
        // className="inline-block p-1"
        >

          {/* <div>
        <label style={{ color: 'crimson', fontSize:15, lineHeight : 1,lineWidth :8, padding: 20}}>Search :&nbsp;
          </label>
        <input  type="text" value={category} placeholder="Product or Category Search" onChange={(event) => searchHandler(event)} />
        </div> */}
          {/* <input type="text" value={category} onChange={(event) => searchHandler(event)} /> */}
          {/* <button onClick={onSearchClick} > Search</button> */}

          {/* <div>
        <input className="search__input" type="text" value={category} placeholder="Search Here" onChange={(event) => searchHandler(event)} />
        </div> */}

          {/* <input type="text" value={category} onChange={(event) => searchHandler(event)} /> */}
          {/* <button onClick={onSearchClick} > Search</button> */}
          {/* <div className="products"> */}
          {/* <div style={{display:'flex'}} className={classes.root}> */}
          <div
          // id="slider"
          // className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth"
          >
            <Grid
              className="products"
              container
              // spacing={0}
              spacing={2}
            // direction='row'
            >
              {/* <Grid container spacing={0} id='slider' className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth' direction='row'  > */}

              {/* (product.category == "Realestate" ? */}
              {/* {categorizedProducts.map((product,id) =>( */}
              {/* {groupByCategoryReduce.All.map((product,index) => ( */}
              {/* {JSON.stringify(arr).map((product) => ( */}
              {Object.values(categorizedProducts).map(
                (product) =>
                  // Number(product.dicos)>50?
                  //  { tag=="avinash"?
                  (tag != 'items' &&
                    Number(product.discount) >= askDiscount &&
                    Number(product.price) >= askPrice &&
                    product?.links?.avinashbmv?.length > 0 && product?.links?.avinashbmv?.length > 0 &&
                    // (product.date == date || dealsFiltertogg) &&
                    (isAllSelected ||
                      (isAmazonSelected &&
                        (product.storeType == 'Amazon' || product.storeType == '')) ||
                      (isFlipkartSelected && product.storeType != 'Amazon')) &&
                    // (isFlipkartSelected && product.tipe == 'Flipkart')) &&
                    // ((product.catagory.toLowerCase().includes(category.toLowerCase()) || (category=="All Categories")) ||
                    ((product &&
                      product.category &&
                      product.category.c1
                        .toLowerCase()
                        .includes(category.toLowerCase()) &&
                      product.urltext
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())) || // &&
                      // (product && product.category && product.category.toLowerCase().includes(category.toLowerCase()) && product.urltext.toLowerCase().includes(searchValue.toLowerCase())) || // &&
                      // (
                      (category == 'All Categories' &&
                        product.urltext
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())) || // &&
                      (product.category.c1.includes(category.toLowerCase()) &&
                        product.urltext
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())))) ||
                    // ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(searchValue.toLowerCase()) ))
                    // &&
                    //  ((product.text_entities[product.text_entities.length-1].type == "plain" && product.text_entities[product.text_entities.length-1].text.toLowerCase().includes(category.toLowerCase()) ) )
                    // ||
                    // &&
                    // (product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())))
                    // (product.from.toLowerCase().includes(category.toLowerCase()))||
                    tag == 'items' ? (
                    <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                      {/* <Grid item xs={6} sm={4} md={3} lg={2} xl={2}> */}
                      {/* <CardProd */}
                      {/* <RecipeReviewCard */}
                      <MediaCard
                        // <ProductCard
                        // className={classes.card}
                        key={product.id}
                        product={product}
                        tag={tag}
                        source={source}
                      />
                    </Grid>
                  ) : // <div key={id}>
                    // <div >
                    // {products.All.map((product) => (
                    //)}
                    // {
                    // products.map(product)=>{
                    // (
                    // {products.All.map(product) =>
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length == 0 && category == "" ||
                    //   (product.from.toLowerCase().includes(category.toLowerCase()))?
                    //   <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //   <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    // </Grid>:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>0 && product.text_entities.length <2?
                    // ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ) ||
                    // (product.from.toLowerCase().includes(category.toLowerCase()))?
                    // (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // <Grid item>
                    //   <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //   <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    // </Grid>:null:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>1 && product.text_entities.length <3?
                    //   ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ||
                    //   (product.from.toLowerCase().includes(category.toLowerCase()))||
                    //   (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase()))) ?

                    //   <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //   <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    // </Grid>:null:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>2 && product.text_entities.length <4?
                    //   ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ||
                    //   (product.from.toLowerCase().includes(category.toLowerCase()))||
                    //   (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase()))||
                    //   (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase()))) ?

                    //     <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //     <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    //   </Grid>:null:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>3 && product.text_entities.length<5?
                    // ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.from.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // <Grid item xs={6} sm={4} md={3} lg={3} xl={2} >
                    //     <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    //   </Grid>:null:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>4 && product.text_entities.length<6?
                    // ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.from.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[4].type == "plain" && product.text_entities[4].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //     <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    //   </Grid>:null:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>5 && product.text_entities.length<7?
                    // ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.from.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[4].type == "plain" && product.text_entities[4].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[5].type == "plain" && product.text_entities[5].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //     <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    //   </Grid>:null:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>6 && product.text_entities.length<8?
                    // ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.from.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[4].type == "plain" && product.text_entities[4].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[5].type == "plain" && product.text_entities[5].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[6].type == "plain" && product.text_entities[6].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //     <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    //   </Grid>:null:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>7 && product.text_entities.length<9?
                    // ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.from.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[4].type == "plain" && product.text_entities[4].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[5].type == "plain" && product.text_entities[5].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[6].type == "plain" && product.text_entities[6].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[7].type == "plain" && product.text_entities[7].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //     <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    //   </Grid>:null:
                    // (tag!="items" && product.date==date) ||
                    //  product.text_entities.length>8 ?
                    // ((product.text_entities[0].type == "plain" && product.text_entities[0].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.from.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[1].type == "plain" && product.text_entities[1].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[4].type == "plain" && product.text_entities[4].text.toLowerCase().includes(category.toLowerCase()))||
                    // (product.text_entities[5].type == "plain" && product.text_entities[5].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[6].type == "plain" && product.text_entities[6].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[7].type == "plain" && product.text_entities[7].text.toLowerCase().includes(category.toLowerCase())) ||
                    // (product.text_entities[8].type == "plain" && product.text_entities[8].text.toLowerCase().includes(category.toLowerCase()))) ?
                    // <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
                    //     <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    //   </Grid>:null:
                    //   null
                    // (product.text_entities[3].type == "plain" && product.text_entities[3].text.toLowerCase().includes(category.toLowerCase()))
                    // text.toLowerCase().includes(category.toLowerCase()) ?
                    // (product.text_entities[2].type == "plain" && product.text_entities[2].text.toLowerCase().includes(category.toLowerCase())) ?
                    //  (product.category == "usm" ?
                    // <div>
                    //   <ProductCard key={product.id} product={product} tag={tag} source={source}/>
                    // </div>
                    // {/* <Information product={product} /> */}
                    // :null )
                    // <ProductCard key={product.id} product={product} / source={source}> )
                    // ))}
                    // ) )}
                    // </div>
                    null,
                // }
              )}
            </Grid>
          </div>
          {/* </div> */}
        </Container>
        <br />
        <br />
        {!dealsFiltertogg ? (
          // s<button onClick={dealsToggleHandler}>View all deals</button>
          <Button
            variant="contained"
            onClick={dealsToggleHandler}
            color="primary"
            // className="add-to-cart-button" 
            style={{
              //   // color: 'crimson',
              fontSize: 12,
              lineHeight: 1,
              alignContent: 'center',
              fillOpacity: 'blue',
            }}
          >
            View all deals
          </Button>
        ) : null}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div>
          <h1></h1>
        </div>
      </div>
    );
  }
}

export default Productsloots;
